import axios from 'axios';
import router from '../router';
import Vue from 'vue';
import store from '@/store';

const apiCall = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    timeout: 10000,
    headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "POST, PATCH, PUT, DELETE, OPTIONS",
        "Accept": "application/json"
    }
});

const loginPath = "/login";

function redirectToLogin() {
    localStorage.removeItem('authenticated');
    localStorage.removeItem('permit');
    router.replace({
        path: loginPath,
        query: {
            redirect: router.currentRoute.fullPath
        }
    });
}

apiCall.interceptors.request.use(
    request => {
        const auth = localStorage.getItem("authenticated");
        if (auth) {
            const permit = localStorage.getItem("permit");
            if (permit) {
                request.headers['Authorization'] = `Bearer ${permit}`;
                // request.headers.common["Authorization"] = 'Bearer ' + permit;
            }
        } 
        return request;
    },
    error => {
        const self = new Vue();
        self.notify('Something went wrong.', 'danger');
        return Promise.reject(error);
    }
);

apiCall.interceptors.response.use(
    response => {
        const self = new Vue();
        if (response.status === 200 || response.status === 201) {
            if (!response.data.status || response.data.status != 'false' ) {
                // self.notify(response.data.response, 'success');
                // if (response.data.response) {
                //     return Promise.resolve(response.data.response);
                // } else {
                    return Promise.resolve(response.data);
                // }
            }
            if (!response.data.response.includes('No Data Found')) {
                self.notify(response.data.response, 'danger');
            }
            store.commit("setLoading", false );
            store.commit("setSidebarLoading", false);
            return Promise.resolve(response.data);
        } else {
            self.notify('Something went wrong on Server.', 'danger');
            return Promise.reject(response);
        }
    },
    error => {
        const self = new Vue();
        if (!error.response) {
            self.notify('Something went wrong on Request.', 'danger');
        } else if (error.response.status) {
            switch (error.response.status) {
                case 401:
                    self.notify(error.response.data.response, 'danger');
                    if (router.currentRoute.name != 'login') {
                        redirectToLogin();
                    }
                    break;
                case 403:
                    self.notify('Something went wrong.', 'danger');
                    // if (router.currentRoute.fullPath != '/login') {
                    //     redirectToLogin();
                    // }
                    break;
                case 404:
                    self.notify('Invalid Api Request.', 'danger');
                    break;
                case 422:
                    self.notify(error.response.data.response, 'danger');
                    break;
                case 500:
                    if (error.response.data.code == 401) {
                        self.notify('You\'re Session Expired.', 'danger');
                            if (router.currentRoute.name != 'Login') {
                                redirectToLogin();
                            }
                    } else {
                        self.notify('Something went wrong.', 'danger');
                    }
                    break;
                case 502:
                    self.notify('Something went wrong. Please try again.', 'danger');
                    if (router.currentRoute.name != 'login') {
                       redirectToLogin();
                        break;
                    }
            }
        }
        return Promise.reject(error.response);
    }
);

export default apiCall;