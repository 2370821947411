import moment from 'moment'
export default {
    methods: {
        decodeHtml(html) {
            let txt = document.createElement("textarea");
            txt.innerHTML = html;
            return txt.value;
        },
        notify(msg, variant = 'primary', title = 'Message', delay = 5000, position = 'top-right') {
            // Use a shorter name for this.$createElement
            const h = this.$createElement
                // Create the message
            const vNodesMsg = h(
                    'p', { class: ['text-center', 'mb-0'] }, [
                        h('b-spinner', { props: { type: 'grow', small: true } }),
                        h('span', { class: 'mx-2' }, `${msg}`),
                        h('b-spinner', { props: { type: 'grow', small: true } })
                    ]
                )
                // Create the title
            const vNodesTitle = h(
                    'div', { class: ['d-flex', 'flex-grow-1', 'align-items-baseline', 'mr-2'] }, [
                        h('strong', { class: 'mr-2' }, `${title}`),
                        // h('small', { class: 'ml-auto text-italics' }, 'Just Now')
                        h('small', { class: 'ml-auto text-italics' }, '')
                    ]
                )
                // Pass the VNodes as an array for message and title
            this.$bvToast.toast([vNodesMsg], {
                title: [vNodesTitle],
                solid: true,
                toaster: `b-toaster-${position}`,
                // autoHideDelay: delay,
                variant: variant
            })
        },

        getAuthUser() {
            let user = window.localStorage.getItem('userInfo');
            return user || false;
        },

        getPermit() {
            let permit = window.localStorage.getItem('permit');
            return permit ? permit : false;
        },
       
        formatJson(filterVal, jsonData) {
            return jsonData.map(v => filterVal.map(j => v[j]));
        },
        logOut(){
            localStorage.removeItem('authenticated');
            localStorage.removeItem('permit');
            localStorage.removeItem('userInfo');
            this.$router.push({name:'login'});
        },
        loginUserName(){
          let user = JSON.parse(this.getAuthUser());
          return user.FirstName;  
        },
        BranchName(){
            let user = JSON.parse(this.getAuthUser());
          return user.BranchName;
        },

        dateFormate(date){
            return this.moment(date).format("DD-MM-YYYY");
        },

        dateFormateYYYYDDMM(date){
            return this.moment(date).format("YYYY-MM-dd");
        },

        generateXlsx(headers, data, file_name = "download") {
            import ('@/Exports/Excel').then(excel => {
                excel.export_json_to_excel({
                    header: headers,
                    data,
                    filename: file_name,
                });
            });
        },
        convertDate(val){
           return moment(val, 'DD-MM-YYYY').format('YYYY-MM-DD');
        },
        formatDate(date) {
            const dateArray = new Date(date).toLocaleString('en-IN').split(',')[0].split("/");
            return (dateArray[0].length > 1 ? dateArray[0] : '0'+ dateArray[0]) + '-' + (dateArray[1].length > 1 ? dateArray[1] : '0'+ dateArray[1]) + '-' + dateArray[2];
          },
        
        // <excel :data="json_data" :fields="fields" header="The list of the Employees" name="employee.xls">
        //     <b-button variant="success"><i class="i-File-Excel"></i></b-button>
        // </excel>
        getValidationState({ dirty, validated, valid = null }) {
            return dirty || validated ? valid : null;
          },
          Number(val) {
            return Number(val);
          },
        
          formattedCommaSystemNumber(val) {
            if (val) {
              val = parseFloat(val).toFixed(2);
              return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            } else return null;
          },

    }
};