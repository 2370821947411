<template>
    <div>
        <div class="side-navbar">
            <router-link to="/customer" style="color: #fff; background-color: #6c757d; padding: 3px 10px; border-radius: 5px;"><i class="fa fa-arrow-left" aria-hidden="true"></i></router-link>
            <div class="navbar-top d-flex">
                <!-- <router-link to="/dashboard"> -->
                <img :src="logo" alt="Lek_Auto_logo" class="img-fluid" />
                <!-- </router-link> -->
            </div>
            <b-tabs class="my-3">
                <b-tab  pills @click="onServiceTabClick" active class="my-1">
                    <template #title>
                          {{ $t('Services') }}
                        </template>
                    <div class="categories-data">
                        <div class="accordion accordion-flush" id="accordionFlushExample">
                            <div class="accordion-item" v-for="(category_list, index) of getCategoryList" :key="index" >
                                <h2 class="accordion-header" :id="'flush-headingOne' + index">
                                    <button @click="getServiceSubCategories(category_list.SERVICE_CATEGORY_SYS_ID)"  v-b-toggle="'category-'+category_list.SERVICE_CATEGORY_SYS_ID" class="accordion-button collapsed"  type="button">
                                        {{category_list.SERVICE_CATEGORY_NAME}}
                                    </button>
                                </h2>
    
                                <b-collapse :id="'category-'+category_list.SERVICE_CATEGORY_SYS_ID" role="tabpanel" accordion="my-accordion">
                                    <div class="accordion-body">
                                        <div class="accordion-item" v-for="(sub_category, sub_category_index) of getCurrentSubCategoryList" :key="sub_category_index">
                                            <h2 class="accordion-header">
                                                <button @click="getservicelist(sub_category.SERVICE_SUB_CATEGORY_SYS_ID)" style="background: #d6caa7 !important;" v-b-toggle="'service-'+sub_category.SERVICE_SUB_CATEGORY_SYS_ID" class="accordion-button collapsed mb-1" type="button">
                                                    {{sub_category.SERVICE_SUB_CATEGORY_NAME}}
                                                </button>
                                            </h2>
                                            <b-collapse :id="'service-'+sub_category.SERVICE_SUB_CATEGORY_SYS_ID" role="tabpanel" accordion="my-accordion-1">
                                                <div class="row">
                                                    <div  class="col-md-6 p-0 mb-1" style="padding-right:2px !important; padding-left:2px !important" v-for="(service, service_index) of getCurrentServiceList" :key="service_index">
                                                        <div class="pro-box2" @click="addItem(service,'SERVICE',sub_category.SERVICE_SUB_CATEGORY_NAME)">
                                                            <label class="frm-label pb-0">{{service.ITEM_NAME}}</label><p style="color:#976b03;">$ {{service.SELL_PRICE}}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </b-collapse>
                                        </div>
                                    </div>
                                </b-collapse>
                            </div>
                        </div>
                    </div>
                </b-tab>
                <b-tab  pills @click="onProductTabClick" class="categories-data">
                    <template #title>
                          {{ $t('Products') }}
                        </template>
                    <div class="accordion accordion-flush" id="accordionFlushExample">
                        <div class="accordion-item" v-for="(productsCategory, index) of getProducts" :key="index">
                            <h2 class="accordion-header" id="flush-heading4">
                            <button v-b-toggle="'product-'+productsCategory.ITEM_CATEGORY_ID" class="accordion-button collapsed" type="button">
                                {{productsCategory.ITEM_CATEGORY_NAME}}
                            </button>
                            </h2>
                            <b-collapse :id="'product-'+productsCategory.ITEM_CATEGORY_ID" role="tabpanel" accordion="my-accordion">
                                <div class="accordion-body">
                                    <div class="row">
                                        <div class="col-md-6 p-0 mb-1" style="padding-right:2px !important; padding-left:2px !important" v-for="(product, index) of productsCategory.PRODUCT_INFO" :key="index">
                                            <div class="pro-box2" @click="addItem(product,'PRODUCt',productsCategory.ITEM_CATEGORY_NAME)">
                                                <label class="frm-label pb-0">{{product.ITEM_NAME}}</label><p style="color:#976b03;">$ {{product.SELL_PRICE}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </b-collapse>
                        </div>
                    </div>
                </b-tab>
                <b-tab  pills @click="onPackageTabClick">
                    <template #title>
                          {{ $t('Packages') }}
                        </template>
                </b-tab>
            </b-tabs>
        </div>
        <!-- modal -->
        <b-modal id="pac-info-modal" size="sm" v-model="canShowPackageInformationModal" centered content-class="shadow" title="Information" @close="canShowPackageInformationModal = false">
            <p class="my-2">
                Package cannot be billed without Customer name.
            </p>
            <template #modal-footer="{ok}">
                <div style="text-align:right">
                    <button  class="btn btn-md btn-secondary" @click="ok()"> OK</button>
                </div>
            </template>
        </b-modal>
    </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
    export default {
        data(){
            return{
                logo: require("@/assets/images/logo.png"),
                  tax_info_details:{},
                  DynamicFlagArray:[],
                  DynamicPackagesArray:[],
                  active_el:"",
                  service_active_el:"",
                  product_active_el:"",
                  canShowPackageInformationModal:false,
            }
        },
    
        mounted() {
            this.onServiceTabClick();
        },
        computed:{
            ...mapGetters([
                "getProducts",
                "getCartItems",
                "getCategoryList",
                "getSubCategoryList",
                "getCurrentSubCategoryList",
                "getServiceList",
                "getCurrentServiceList",
                "isWalletPresent"
            ]),
        },

        methods:{
            ...mapActions([
                "AddToCategories",
                "AddToSubCategories",
                "updateActiveCategory",
                "AddToServices",
                "updateActiveSubCategory",
                "addProducts",
                "addToCart",
                "updateCart",
            ]),
            onServiceTabClick() {
                if (this.getCategoryList.length > 0) {
                    return false;
                }
                // storing here service categories from api to vue store{
                this.$store.commit("setSidebarLoading", true);
                this.$apiCall.get(this.$apiBus.category_list, {
                    params : {
                        ITEM: "VIEW_ALL",
                        RECORD_SYS_ID: 0,
                    }
                }).then(res => {
                    this.AddToCategories(res.status == 'true' ? res.response : []);
                    this.$store.commit("setSidebarLoading", false);
                });
            },
            onProductTabClick() {
                if (this.getProducts.length <= 0) {
                    this.getproductlist();
                }
            },
            onPackageTabClick() {
                if (!this.$route.query.ORDER_ID && !this.$route.params.CUSTOMER_NAME) {
                    this.canShowPackageInformationModal = true;
                }
            },
            getServiceSubCategories(categoryId){
                this.updateActiveCategory(categoryId);
                // api call for service sub
                if (this.getSubCategoryList.length > 0) {
                    const existedCategory = this.getSubCategoryList.find(item => item.SERVICE_CATEGORY_SYS_ID === categoryId);
                    if (existedCategory) {
                        return false;
                    }
                }
                this.$store.commit("setSidebarLoading", true);
                this.$apiCall.get(this.$apiBus.get_sub_categories, {
                    params : {
                        ITEM: "SPECIFIC",
                        RECORD_SYS_ID: categoryId
                    }
                }).then(res => {
                    this.AddToSubCategories((res.status == 'true') ? res.response : []);
                    this.$store.commit("setSidebarLoading", false);
                });
            },
            getservicelist(subCatId) {
                this.updateActiveSubCategory(subCatId);
                if (this.getServiceList.length > 0) {
                    const existingService = this.getServiceList.find(item => item.SERVICE_SUB_CATEGORY_SYS_ID === subCatId);
                    if (existingService) {
                        return false;
                    }
                }
                this.$store.commit("setSidebarLoading", true);
                this.$apiCall.get(this.$apiBus.ViewServiceInfo, {
                    params : {
                        ITEM: "VIEW_ALL",
                        SUB_CATEGORY_SYS_ID: subCatId
                    }
                }).then(res => {
                    this.AddToServices((res.status == 'true') ? res.response : []);
                    this.$store.commit("setSidebarLoading", false);
                });
            },

            getproductlist() {
                if (this.getProducts.length > 0) {
                    return false;
                }
                this.$store.commit("setSidebarLoading", true);
                this.$apiCall.get(this.$apiBus.product_list, {
                    params : {
                        ITEM: "VIEW_ALL",
                        RECORD_SYS_ID:0,
                        PRODUCT_SKU_CODE: "",
                        BRAND_NAME:"",
                        SERIES_NAME:"",
                        PRODUCT_TYPE:"",
                        BAR_CODE:"",
                        PRODUCT_NAME:""
                    }
        
                }).then(res => {
                    this.tax_info_details = res.response;
                    this.addProducts(res || []);
                    this.$store.commit("setSidebarLoading", false);
                });
            },

            //Get Packages Info
            GetPackagesInfo(){
                this.$apiCall.get(this.$apiBus.ViewPackagesInfo, {
                params : {
                    ITEM: "SPECIFIC",
                    BRANCH_SYS_ID:"1"
                }
                }).then(res => {
                    this.DynamicPackagesArray = res;
               });
            },

            addItem(addedItem,itemType,itemCategoryName){
                let items = JSON.parse(JSON.stringify(this.getCartItems));
                const cartItem = addedItem;
                cartItem['ORDER_DETAILS_ID']= 0;
                cartItem['ITEM_SKU_CODE'] = addedItem.SKU_REF_CODE;
                cartItem['QTY'] = cartItem.Qty ? cartItem.Qty : 1;
                cartItem['CURRENCY_SYS_ID'] = 1;
                cartItem['UNIT_PRICE'] = this.isWalletPresent ? 0 : cartItem.SELL_PRICE;
                cartItem['DISCOUNT_VALUE']= 0;
                cartItem['TOTAL_PRICE'] = this.isWalletPresent ? 0 : cartItem.SELL_PRICE;
                cartItem["ITEM_TYPE"] = itemType;

                cartItem["ITEM_CATEGORY_NAME"] = itemCategoryName;
                items.map(e =>{
                    if (this.isWalletPresent) {
                        e.UNIT_PRICE = e.ITEM_TYPE != 'CREDIT_WALLET' || e.ITEM_TYPE != 'FOC_WALLET_AMOUNT'  ? 0 : e.UNIT_PRICE;
                        e.TOTAL_PRICE = e.ITEM_TYPE != 'CREDIT_WALLET' || e.ITEM_TYPE != 'FOC_WALLET_AMOUNT' ? 0 : e.TOTAL_PRICE;
                        e.MIN_SELL_PRICE = e.ITEM_TYPE != 'CREDIT_WALLET' || e.ITEM_TYPE != 'FOC_WALLET_AMOUNT' ? 0 : e.MIN_SELL_PRICE;
                        this.$forceUpdate();
                    }
                    return e;
                });
                let existedCartItem = items.find(i => (i.ITEM_ID === cartItem.ITEM_ID && i.ITEM_TYPE === cartItem.ITEM_TYPE));
                if (existedCartItem) {
                    // if item already available in cart
                    existedCartItem.QTY += 1;
                    existedCartItem.TOTAL_PRICE = (parseInt(existedCartItem.QTY) * parseInt(existedCartItem.UNIT_PRICE));
                    this.updateCart(existedCartItem);
                } else if (items.length <= 0 || !existedCartItem) {
                    items.push(cartItem);
                    this.addToCart(items);
                }
            },


        },
    }
</script>

<style lang="scss" scoped>
@import "@/assets/css/style.css";
@import "@/assets/css/style.scss";
.side-navbar .active {
    color: #fff;
    // background-image: linear-gradient(to right, #BD9B36 , #976b03) !important;
}
.wrapper .side-navbar ul li {
    padding: 0px;
    margin-top: 0px;
    border-bottom: 0px;
    box-shadow:none;
    color: #212121;
}
.wrapper .side-navbar li:hover {
    background: none;
    color: #212121;

}
.nav-link:hover, .nav-link:focus {
    color: #212121 !important;
}
.nav-link:hover, .nav-link:focus {
    color: #212121;
}
.categories-data .active {
    background: none !important;
}
.accordion-button:not(.collapsed)::after {
    background-image: var(--bs-accordion-btn-icon) !important;
}
</style>