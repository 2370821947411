import Vue from "vue";
import VueI18n from "vue-i18n";
Vue.use(VueI18n);

const DEFAULT_LANG = localStorage.getItem("i18n") || "eng";

const localeLanguages = {
  en: require("./i18n/eng.json"),
  chi: require("./i18n/chi.json"),
};
const i18n = new VueI18n({
  locale: DEFAULT_LANG,
  messages: localeLanguages,
});

export default i18n;
