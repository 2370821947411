<template>
    <div class="hidden-print">
        <div class="side-navbar">
            <div class="navbar-top text-center">
                <!-- <a href=""> -->
                    <img :src='logo' alt="Lek_Auto_logo" class="img-fluid" />
                    <!-- </a> -->
            </div>
            <div class="row mt-3">
                <div class="col-md-6">
                    <router-link to="/dashboard">
                        <div class="nav-box" :class="[isActive('/dashboard') ? 'active' : '' ]">
                            <i class="fa fa-home" aria-hidden="true"></i>
                            <p>{{ $t('Home') }}</p>
                        </div>
                    </router-link>
                </div>
                <div class="col-md-6">
                    <router-link to="/sale">
                        <div class="nav-box" :class="[isActive('/sale') ? 'active' : '' ]">
                            <i class="fa fa-tag" aria-hidden="true"></i>
                            <p>{{ $t('Sale') }}</p>
                        </div>
                    </router-link>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-md-6">
                    <router-link to="/customer">
                        <div class="nav-box" :class="[isActive('/customer') ? 'active' : '' ]">
                            <i class="fa fa-user" aria-hidden="true"></i>
                            <p>{{ $t('Customer') }}</p>
                        </div>
                    </router-link>
                </div>
                <div class="col-md-6">
                    <router-link to="/appointment">
                        <div class="nav-box" :class="[isActive('/appointment') ? 'active' : '' ]">
                            <i class="fa fa-calendar" aria-hidden="true"></i>
                            <p>{{ $t('Appointment') }}</p>
                        </div>
                    </router-link>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-md-6">
                    <router-link to="/transactions">
                        <div class="nav-box" :class="[isActive('/transactions') ? 'active' : '' ]">
                            <i class="fa fa-exchange" aria-hidden="true"></i>
                            <p>{{ $t('Transactions') }}</p>
                        </div>
                    </router-link>
                </div>
                <div class="col-md-6">
                    <router-link to="/products">
                        <div class="nav-box" :class="[isActive('/products') ? 'active' : '' ]">
                            <i class="fa fa-cubes" aria-hidden="true"></i>
                            <p>{{ $t('Products') }}</p>
                        </div>
                    </router-link>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-md-6">
                    <router-link to="/services">
                        <div class="nav-box" :class="[isActive('/services') ? 'active' : '' ]">
                            <i class="fa fa-scissors" aria-hidden="true"></i>
                            <p>{{ $t('Services') }}</p>
                        </div>
                    </router-link>
                </div>
                <div class="col-md-6">
                    <router-link to="/reports">
                        <div class="nav-box" :class="[isActive('/reports') ? 'active' : '' ]">
                            <i class="fa fa-file-text" aria-hidden="true"></i>
                            <p>{{ $t('Reports') }}</p>
                        </div>
                    </router-link>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-md-6">
                    <router-link to="/commission">
                        <div class="nav-box" :class="[isActive('/commission') ? 'active' : '' ]">
                            <i class="fa fa-inr" aria-hidden="true"></i>
                            <p>{{ $t('Commission') }}</p>
                        </div>
                    </router-link>
                </div>
                <div class="col-md-6">
                    <router-link to="/stock">
                        <div class="nav-box" :class="[isActive('/stock') ? 'active' : '' ]">
                            <i class="fa fa-line-chart" aria-hidden="true"></i>
                            <p>{{ $t('Stock') }}</p>
                        </div>
                    </router-link>
                </div>
                </div>
                <div class="row mt-3">
                <div class="col-md-6">
                    <router-link to="/receivable">
                        <div class="nav-box" :class="[isActive('/receivable') ? 'active' : '' ]">
                            <i class="fa fa-credit-card" aria-hidden="true"></i>
                            <p>{{ $t('Outstanding Payment Amount') }}</p>
                        </div>
                    </router-link>
                </div>
                <div class="col-md-6">
                    <router-link to="/complete-transactions">
                        <div class="nav-box" :class="[isActive('/complete-transactions') ? 'active' : '' ]">
                            <i class="fa fa-credit-card" aria-hidden="true"></i>
                            <p>{{ $t('Complete Payment Amount') }}</p>
                        </div>
                    </router-link>
                </div>
               
           
             
                </div>
            </div>
        </div>
    
</template>
<script>
    export default {
        data(){
            return{
                logo: require("@/assets/images/logo.png"),
            }
        },
        methods:{
            isActive(path) {
                return this.$route.path == path || this.$route.path.includes(path);
            }
        },
        computed:{
            is_current_path(){
                return this.$store.state.is_current_path
            }
        },
        created(){}
    }
</script>

<style lang="scss" scoped>
@import "@/assets/css/style.css";
@import "@/assets/css/style.scss";
@media print {
    .hidden-print,
    .hidden-print * {
        display: none !important;
    }
}
</style>