import Vue from "vue";
import VueRouter from "vue-router";
import Layout from "@/views/Layout.vue";
import Layout2 from "@/views/Layout2.vue";
import Layout3 from "@/views/Layout3.vue";
import Layout4 from "@/views/Layout4.vue";

Vue.use(VueRouter);

const routes = [
  // Layout-1
  {
    path: "/",
    redirect: "dashboard",
    component: Layout,
    children:[
      {
        path: "customer-old-2",
        name: "customer-old-2",
        component: () => import("@/views/pages/customer/Index.vue"),
      },
      {
        path: "customer-new",
        name: "customer-new",
        component: () => import("@/views/pages/customer/IndexNew.vue"),
      },
      {
        path: "customer",
        name: "customer",
        component: () => import("@/views/pages/customer/IndexNew2.vue"),
      },
      {
        path: "old-customer",
        name: "old-customer",
        component: () => import("@/views/pages/customer/IndexOld.vue"),
      },
      {
        path: "customer/add",
        name: "add-customer",
        component: () => import("@/views/pages/customer/Add.vue"),
      },
      {
        path: "/payment/:CUSTOMER_SYS_ID/:CUSTOMER_NAME/:CUSTOMER_CONTACT/:ADDRESS_LINE_1/:WALLET_AMOUNT/:RECORD_SYS_ID",
        name: "payment",
        component: () => import("@/views/pages/Payment.vue"),
      },
      {
        path: "/:RECORD_SYS_ID/invoice",
        name: "invoice",
        component: () => import("@/views/pages/Invoice.vue"),
      },
      {
        path: "dashboard",
        name: "dashboard",
        component: () => import("@/views/Dashboard.vue"),
      },
      {
        path: "services",
        name: "services",
        component: () => import("@/views/pages/services/Services.vue"),
      },
      {
        path: "reports",
        name: "reports",
        component: () => import("@/views/pages/reports/Reports.vue"),
      },
      {
        path: "commission",
        name: "commission",
        component: () => import("@/views/pages/commission/commission.vue"),
      },
      {
        path: "receivable",
        name: "receivable",
        component: () => import("@/views/pages/Payment/Receivable.vue"),
      },
     
      {
        path: "stock",
        name: "stock",
        component: () => import("@/views/pages/stock/stock.vue"),
      },
      {
        path: "products",
        name: "products",
        component: () => import("@/views/pages/products/Products.vue"),
      },
      {
        path: "packages",
        name: "packages",
        component: () => import("@/views/pages/packages/Packages.vue"),
      },
      {
        path: "transactions",
        name: "transactions",
        component: () => import("@/views/pages/transactions/Transactions.vue"),
      },
      {
        path: "complete-transactions",
        name: "complete-transactions",
        component: () => import("@/views/pages/transactions/CompletePayment.vue"),
      },
    ],
  },
  // Layout-2
  {
    path: "/",
    component: Layout2,
    children:[
      {
        path: "place-order",
        name: "place-order",
        component: () => import("@/views/pages/PlaceOrder.vue"),
      },
      {
        path: "sale/:CUSTOMER_SYS_ID?/:CUSTOMER_NAME?/:CUSTOMER_CONTACT?/:ADDRESS_LINE_1?/:WALLET_AMOUNT?",
        name: "sale",
        component: () => import("@/views/pages/Sale/sale.vue"),
      },
    ],
  },
  // Layout-3
  {
    path: "/",
    component: Layout3,
    children:[
      {
        path: "add-packages",
        name: "addpackages",
        component: () => import("@/views/pages/packages/AddPackages.vue"),
      }
    ],
  },
  // Layout-4
  {
    path: "/",
    component: Layout4,
    children:[
      {
        path: "appointment",
        name: "appointment",
        component: () => import("@/views/pages/Appointment.vue"),
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/pages/auth/Login.vue"),
  },
  {
    path: "/",
    component: Layout,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const publicPages = ["/login"];
  const authRequired = !publicPages.includes(to.path);

  if (authRequired) {
    let user = window.localStorage.getItem('userInfo');
      if (user) {
        let user_info = JSON.parse(user);
        if (user_info.Token) {
              next();
          } else {
              return next("/login");
          }
      } else {
          return next("/login");
      }
  }
  next();
});

export default router;
