const api_url = process.env.VUE_APP_API_URL;
const endpoints = {
    login: api_url + "Authentication/api-login",
    ViewServiceInfo:  api_url + "WebApi/api-get-view-services-info",
    get_customer: api_url + "WebApi/api-get-view-customer-info",
    add_customer: api_url + "WebApi/api-post-add-update-customer-info",
    update_customer: api_url + "WebApi/api-post-add-update-customer-info",
    ViewProductInfo:  api_url + "WebApi/api-get-view-product-info",
    category_list:api_url + "WebApi/api_get_view_service-category-name-info",
    product_list:api_url + "WebApi/api-get-view-product-info",
    ViewPackagesInfo:api_url + "WebApi/api-get-view-package-info",
    addapoitment:api_url + "WebApi/api-post-add-update-appointment-info",
    employee:api_url + "WebApi/api-get-view-branch-user-info",
    view_appoitmeant:api_url + "WebApi/api-get-view-branch-user-info",
    add_service:api_url + "WebApi/api_post_add_update_service_transaction_info",
    ViewTransactionInfo:api_url + "WebApi/api-get-view-transaction-details",
    ViewIncompletetransaction:api_url + "WebApi/api-get-view-transaction-details",
    place_order: api_url + "WebApi/api_post_add_update_pos_order_info",
    confirm_order: api_url + "WebApi/api_post_add_update_pos_order_info",
    order_details:api_url + "WebApi/api-get-view-order-info",
    add_update_package_info:api_url + "WebApi/api_post_add_update_package_info",
    get_package_category_info:api_url + "WebApi/api_get_view_package_category_info",
    get_payment_mothods:api_url + "WebApi/api_get_view_payment_mode",
    get_appointment_details:api_url + "WebApi/api-get-view-appointment-info",
    get_sub_categories: api_url + "WebApi/api-get-view-service-sub-category-name-info",
    get_appointment_details_range_wise: api_url + "WebApi/api-get-view-appointment-range-specific-info",
    get_customer_package_info:api_url + "WebApi/api-get-view-customer-package-info",
    get_staff_salesreport_info:api_url + "WebApi/api-view-staff-sales-report",
    get_customer_transaction_info:api_url + "WebApi/api-get-view-customer-transaction-info",
    add_medical:api_url + "WebApi/api-post-add-update-customer-medical-record",
    view_medical:api_url + "WebApi/api-get-view-customer-medical-record",
    complete_event:api_url + "WebApi/api_post_add_update_cust_package_transaction_info",
    get_staff_servicesreport_info:api_url + "WebApi/api-view-staff-service-report",
    get_staff_productsalesreport_info:api_url + "WebApi/api-view-product-sales-report",
    get_view_working_employee_data: api_url + "WebApi/api-get-view-employee-working-roster-info",
    add_update_customer_wallet: api_url + "WebApi/api-post-add-update-customer-wallet",
    get_service_search_details: api_url + "WebApi/api-get-view-services-search-details",
    get_view_customer_search: api_url + "WebApi/api-get-view-customer-search",
    get_transaction_report: api_url + "WebApi/api-get-transaction-report",  
    view_commission:api_url + "WebApi/api-get-view-emp-commission-info",
    add_commission:api_url + "WebApi/api-post-add-update-commission",
    add_update_stock:api_url + "WebApi/api-post-add-update-stock",
    view_product_stock_info:api_url + "WebApi/api-get-view-product-stock-info",
    view_stock_list_info:api_url + "WebApi/api-get-view-stock-list-info",
    stock_supplier_price:api_url + "WebApi/api-get-view-stock-supplier-price-info",
    stock_calculate:api_url + "WebApi/api-get-view-stock-supplier-price-info",
    view_customer_wallet_history:api_url + "WebApi/api-get-view-customer-wallet-history",
    view_product_search : api_url + "WebApi/api-get-view-product-search-info",
    view_product_search : api_url + "WebApi/api-get-view-product-search-info",
    view_branch : api_url + "WebApi/api_get_view_branch_info",
    view_customer_wallet_history:api_url + "WebApi/api-get-view-customer-wallet-history",
    get_view_stock_report: api_url + "WebApi/api-get-view-product-stock-report",
    view_customer_appointment: api_url + "WebApi/api-get-view-product-stock-report",
    customer_wise_appointment:api_url + "WebApi/api-get-view-customer-appointment-info",
    Dashboard:api_url + "WebApi/api-get-view-dashboard-count",
    add_update_order_payment_details_info: api_url + "WebApi/api-post-add-update-order-payment-details",  
    view_order_payment_details_info: api_url + "WebApi/api-get-view-order-payment-details-info",
    view_order_payment_info: api_url + "WebApi/api-get-view-order-payment-info",
    view_payment_mode: api_url + "WebApi/api-get-view-payment-mode", 
    view_product_sales_report:api_url + "WebApi/api-get-view-product-sales-report-activity", 
    dashboard_data: api_url + "WebApi/api-get-view-dashboard-count",
    view_sales_report_new:api_url + "WebApi/api-get-view-daily-sales-report",
   
};
    

export default endpoints;